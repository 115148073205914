import React, { useState } from "react";
import { connect } from "react-redux";

import { updateCreatorWithCallback } from "../actions/";
import {
	followersChanged
} from "../actions/AppActions";
import NewCreatorStep1 from "./NewCreator/NewCreatorStep1";
import NewCreatorStep2 from "./NewCreator/NewCreatorStep2";
import NewCreatorStep3 from "./NewCreator/NewCreatorStep3";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Dialog,
	DialogTitle as MuiDialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Grid,
	CircularProgress
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {handleChangeArrayObjectValue, handleChangeValue} from "./utils";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%"
	},
	backButton: {
		marginRight: theme.spacing(1)
	},
	mobileStepper: {
		paddingRight: 0,
		paddingLeft: 0
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900]
	},
	formContainer: {
		maxWidth: 500,
		margin: "auto"
	},
	paper: {
		justifyContent: "center",
		flexWrap: "wrap",
		marginTop: theme.spacing(2),
		padding: theme.spacing(1),
		backgroundColor: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.23)"
	},
	paperTitle: {
		marginTop: -21,
		maxWidth: "fit-content",
		background: "white",
		paddingRight: 5,
		paddingLeft: 5
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	chip: {
		margin: theme.spacing(1)
	},
	buttonProgressRate: {
		position: "absolute",
		right: "30px",
		bottom: "13px"
	}
}));

const requiredStep = {
	firstname: false,
	lastname: false,
	instagram: false,
	followers: false,
	email: false,
	phone: false,
	address: false,
	photos: false,
	instagramStats: {
		male: false,
		female: false,
		likes: false,
		engagement: false,
		storyViews: false,
		postImpressions: false,
	}
};

const EditCreator = props => {
	const classes = useStyles();
	const [creator, setCreator] = useState(props.creator);
	const [creatorLoading, setCreatorLoading] = useState(false);
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
	const [currErrors, setCurrErrors] = useState({ ...requiredStep });
	const [hasUpdated, setHasUpdated] = React.useState("");

	const checkErrors = _ => {
		let required = { ...requiredStep };
		for (let [key, value] of Object.entries(creator)) {
			Object.keys(required).forEach(keyReq => {
				if (Array.isArray(value)) {
					if (key === keyReq && value.some(item => item === "")) {
						required[key] = true;
					} else if (key === keyReq && !value.some(item => item === "")) {
						required[key] = false;
					}
				} else if (typeof value === "object") {
					if (
						key === keyReq &&
						Object.values(value).some((item) => item === "")
					) {
						if(key === "instagramStats"){
							for (let [key, value] of Object.entries(creator.instagramStats)) {
								Object.keys(required.instagramStats).forEach((keyReq) => {
									if (key === keyReq && value === ""){
										required.instagramStats[key] = true;
									}
									else if (key === keyReq && value !== ""){
											required.instagramStats[key] = false;
									}
								})
							}
						}
						else required[key] = true;
					} else if (
						key === keyReq &&
						!Object.values(value).some(item => item === "")
					) {
						required[key] = false;
					}
				} else {
					if (keyReq === key && value === "") {
						required[key] = true;
					} else if (keyReq === key && value !== "") {
						required[key] = false;
					}
				}
			});
		}

		return Object.values(required).findIndex(item => item) === -1
			? false
			: required;
	};

	const save = _ => {
		const errors = checkErrors();
		if (errors) {
			setCurrErrors({ ...errors });
		} else {
			setCreatorLoading(true);
			props.updateCreator(creator, _ => {
				props.setCreator(creator);
				props.setOpen(false);
				setCreatorLoading(false);
			});
		}
	};

	const handleChange = (e) => {
		setCreator({ ...creator, [e.target.name]:handleChangeValue(e)});
	}

	const handleChangePrimaryChannel = (objectName, callFn = ()=>{}) => e => {
		let currentCreator = {...creator};
		let object = { ...creator[objectName] };
		let targetValue = e.target.value;
		if(e.target.type === "checkbox"){
			targetValue = e.target.checked
			const channels = ['instagramStats','youtubeStats','tiktokStats']
			if(channels.includes(objectName)){
				channels.forEach(channel => {
					if(creator[channel].primary){
			 			let currentChannel = { ...creator[channel] };
			 			currentChannel.primary = false;
			 			currentCreator[channel] = currentChannel;
			 			setHasUpdated(channel);
					}
		 		})
			}

		}
		object[e.target.name] = targetValue;
		currentCreator[objectName] = object;
		setCreator(currentCreator);
		callFn(e);
	}

	const handleChangeObject = (objectName, callFn = ()=>{}) => (e) => {
		let object = { ...creator[objectName] };
		let targetValue = e.target.value;
		if(e.target.type === "checkbox"){
			targetValue = e.target.checked
		}
		object[e.target.name] = targetValue;
		followersChanged(creator,objectName,targetValue)
		setCreator({ ...creator, [objectName]: object });
		callFn(e);
	};

	const handleChangeArrayObject = (index, attribute, object, objectName) => (e) => {
		setCreator({ ...creator, [objectName] : handleChangeArrayObjectValue(e, index, attribute, object) });
	};

	const handleChangeArray = (index, attribute) => e => {
		let newArray = creator[e.target.name];
		newArray[index][attribute] = e.target.value;
		setCreator({ ...creator, [e.target.name]: newArray });
	};

	const handleChangeArrayPhotos = (index, value) => {
		let newArray = creator.photos;
		newArray[index] = value;
		setCreator({ ...creator, photos: newArray });
	};

	const handleChangeArrayManagers = value => {
		let newArray = creator.managers;
		newArray.push(value);
		setCreator({ ...creator, managers: newArray });
	};

	const handleDeleteArrayManagers = index => {
		let newArray = creator.managers;
		newArray.splice(index, 1);
		setCreator({ ...creator, managers: newArray });
	};

	const addField = (voidObject, target, objectName) => {
		let newArray = creator[objectName][target];
		newArray.push(voidObject);
		let object = creator[objectName];
		object[target] = newArray;
		setCreator({ ...creator, [objectName]: object });
	};

	return (
		<Dialog
			onClose={_ => props.setOpen(false)}
			aria-labelledby="customized-dialog-title"
			open={props.open}
			onExit={_ => {
				setCurrErrors({ ...requiredStep });
			}}
			fullWidth
			maxWidth="lg"
			fullScreen={isMobile}
		>
			<MuiDialogTitle disableTypography className={classes.root}>
				<Typography variant="h6">EDIT CREATOR</Typography>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={_ => props.setOpen(false)}
				>
					<CloseIcon />
				</IconButton>
			</MuiDialogTitle>
			<DialogContent dividers>
				<Grid container>
					<Grid item xs={12} lg={props.user.role !== "creator" ? 6 : 12}>
						<NewCreatorStep1
							creator={creator}
							handleChange={handleChange}
							handleChangeObject={handleChangeObject}
							classes={classes}
							errors={currErrors}
						/>
					</Grid>
					{props.user.role !== "creator" && (
						<Grid item xs={12} lg={6}>
							<NewCreatorStep3
								creator={creator}
								handleChange={handleChange}
								handleChangeArrayManagers={handleChangeArrayManagers}
								handleDeleteArrayManagers={handleDeleteArrayManagers}
								classes={classes}
								errors={currErrors}
							/>
						</Grid>
					)}
				</Grid>
				<NewCreatorStep2
					creator={creator}
					handleChangeObject={handleChangeObject}
					handleChangePrimaryChannel={handleChangePrimaryChannel}
					handleChangeArrayObject={handleChangeArrayObject}
					handleChange={handleChange}
					handleChangeArray={handleChangeArray}
					handleChangeArrayPhotos={handleChangeArrayPhotos}
					addField={addField}
					hasUpdated={hasUpdated}
					classes={classes}
					errors={currErrors}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={_ => props.setOpen(false)}>Close</Button>
				<div>
					<Button
						disabled={creatorLoading}
						variant="contained"
						className={classes.button}
						onClick={save}
					>
						Save
					</Button>
					{creatorLoading && (
						<CircularProgress
							size={24}
							className={classes.buttonProgressRate}
						/>
					)}
				</div>
			</DialogActions>
		</Dialog>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateCreator: (creator, callback) =>
			dispatch(updateCreatorWithCallback(creator, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCreator);
