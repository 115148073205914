import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
import "firebase/performance";
import "firebase/analytics";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyAEPagFGM-eIXPj0B99eQCLkZatLyPpGn4",
	authDomain: "creators-manager.firebaseapp.com",
	databaseURL: "https://creators-manager.firebaseio.com",
	projectId: "creators-manager",
	storageBucket: "creators-manager.appspot.com",
	messagingSenderId: "1023234974429",
	appId: "1:1023234974429:web:4fcacfa97b35d3a3d8b134",
	measurementId: "G-NQ8PTF5BE0"
};
const firebaseTestConfig = {
	apiKey: "AIzaSyBQ-nQiNjhwmSzfSROrTBeZx4hErTyuXTI",
	authDomain: "test-ba309-294901.firebaseapp.com",
	databaseURL: "https://test-ba309-294901.firebaseio.com",
	projectId: "test-ba309-294901",
	storageBucket: "test-ba309-294901.appspot.com",
	messagingSenderId: "1059674605125",
	appId: "1:1059674605125:web:ac830b6dc5f166100ac52c",
	measurementId: "G-NQ8PTF5BE0"
};

// firebase.initializeApp(firebaseTestConfig);



firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const connectedRef = firebase.database().ref(".info/connected");
export const firebaseRef = firebase;
export const fbFunctions = firebase.functions();
export const fbPerf = firebase.performance();
export const storageRef = firebase.storage().ref();
