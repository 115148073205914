import React, { useState } from "react";
import { connect } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { GoogleLogin } from "react-google-login";

import NewEvent from "./NewEvent";
import ViewProject from "./Project/ViewProject";
import NewProject from "./Project/NewProject";
import { updateUser, updateProject, deleteProject } from "../actions";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
	Grid,
	Fade,
	Typography,
	Grow,
	Fab,
	useMediaQuery,
	Button
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { Add } from "@material-ui/icons";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "../style/Calendar.css";

const useStyles = makeStyles(theme => ({
	addButton: {
		backgroundColor: "#cd9988",
		position: "absolute",
		right: 34,
		top: 40,
		color: "white",
		height: 42,
		minWidth: 42,
		width: 42,
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	},
	button: {
		backgroundColor: "#cd9988",
		color: "white",
		"&:hover": {
			backgroundColor: "#e8ad9c"
		}
	}
}));

const Calendar = props => {
	const classes = useStyles();
	const [addEvent, setAddEvent] = useState(false);
	const [addProject, setAddProject] = useState(false);
	const [viewProject, setViewProject] = useState(false);
	const [project, setProject] = useState(null);
	const [event, setEvent] = useState(null);
	const { creator } = props;
	const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

	const eventsList = _ => {
		const events = [];
		creator.projects.forEach(project => {
			project.deliverables.forEach(deli => {
				events.push({
					title: project.brand + " Approval " + deli.name,
					date: deli.approval.date ? deli.approval.date.format("YYYY-MM-DD"): null,
					start: deli.approval.startDate ? deli.approval.startDate.format("YYYY-MM-DD"): null,
					end: deli.approval.endDate ? deli.approval.endDate.format("YYYY-MM-DD HH:mm"): null,
					color: "#000000",
					className: "noTime",
					extendedProps: {
						id: project.id + "-approval",
						type: "project",
					}
				});
				events.push({
					title: project.brand + " " + deli.name,
					date: deli.post.date ? deli.post.date.format("YYYY-MM-DD"): null,
					start: deli.post.startDate ? deli.post.startDate.format("YYYY-MM-DD"): null,
					end: deli.post.endDate ? deli.post.endDate.format("YYYY-MM-DD HH:mm"): null,
					className: "noTime",
					extendedProps: {
						id: project.id + "-post",
						type: "project"
					}
				});
			});
		});
		creator.events.forEach(event => {
			events.push({
				title: event.name,
				start: event.date.format("YYYY-MM-DD HH:mm"),
				end: event.endDate.format("YYYY-MM-DD HH:mm"),
				color: grey[500],
				extendedProps: {
					id: event.id,
					type: "event"
				}
			});
		});
		return events;
	};

	const responseGoogle = response => {
		console.log(response);
	};

	return (
		<>
			<Fade in={true}>
				<Grid container>
					<Grid item xs={12} style={{ marginBottom: 60 }}>
						<Typography
							variant="h6"
							style={{
								textTransform: "uppercase",
								textAlign: "center",
								lineHeight: 1.3,
								marginTop: 20,
								fontWeight: "bold"
							}}
						>
							Calendar
						</Typography>
						<Typography
							variant="h6"
							style={{
								textAlign: "center"
							}}
						>
							{props.user.role === "creator" ? (
								typeof props.user.googleCode === "undefined" ? (
									<GoogleLogin
										clientId="1023234974429-bvfqnfsrnko3s4pvv5d67kave43dqma1.apps.googleusercontent.com"
										buttonText="Login"
										onSuccess={res => {
											if (res.code) {
												props.updateUser({ ...props.user, googleCode: res.code });
											}
										}}
										onFailure={responseGoogle}
										scope={"https://www.googleapis.com/auth/calendar"}
										accessType="offline"
										responseType="code"
										prompt="consent"
										isSignedIn={true}
										redirectUri="https://house.iam-influenceall.com/"
										cookiePolicy={"single_host_origin"}
										render={renderProps => (
											<Button
												onClick={renderProps.onClick}
												variant="contained"
												className={classes.button}
												disabled={renderProps.disabled}
											>
												Google Calendar Sync
											</Button>
										)}
									/>
								) : (
									<Button
										onClick={_ => {
											const userCopy = { ...props.user };
											delete userCopy.googleCode;
											delete userCopy.googleRefresh;
											props.updateUser({ ...userCopy });
										}}
										variant="contained"
										className={classes.button}
									>
										Google Calendar Logout
									</Button>
								)
							) : null}
						</Typography>

						<Grow in={true}>
							<Fab
								className={classes.addButton}
								style={isMobile ? { top: 80 } : null}
								aria-label="new"
								onClick={_ => setAddEvent(true)}
								size="medium"
							>
								<Add />
							</Fab>
						</Grow>
					</Grid>
					<Grid item xs={12}>
						<FullCalendar
							defaultView="dayGridMonth"
							events={eventsList()}
							plugins={[dayGridPlugin]}
							eventClick={info => {
								if (info.event.extendedProps.type === "project") {
									setProject(
										creator.projects.find(
											project =>
												project.id === info.event.extendedProps.id.split("-")[0]
										)
									);
									setViewProject(true);
								} else {
									setEvent(
										creator.events.find(
											event => event.id === info.event.extendedProps.id
										)
									);
									setAddEvent(true);
								}
							}}
						/>
					</Grid>
				</Grid>
			</Fade>
			<NewEvent
				open={addEvent}
				setOpen={setAddEvent}
				mode={event === null ? "new" : "edit"}
				creator={creator}
				event={event}
				setEvent={setEvent}
			/>
			<NewProject
				setOpen={setAddProject}
				open={addProject}
				creator={creator}
				editProject={project}
				setEditProject={setProject}
			/>
			<ViewProject
				setOpen={setViewProject}
				open={viewProject}
				project={project}
				openEdit={projectId => {
					setViewProject(false);
					setProject(
						creator.projects.find(project => project.id === projectId)
					);
					setAddProject(true);
				}}
			/>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return {
		updateUser: user => dispatch(updateUser(user)),
		updateProject: (project, creator, callback) =>
			dispatch(updateProject(project, creator, callback)),
		deleteProject: (project, creator, callback) =>
			dispatch(deleteProject(project, creator, callback))
	};
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
