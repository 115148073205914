import { Box, Grid, Typography} from "@material-ui/core";
import CreatorStatistics from "./CreatorStatistics";
import CreatorDemographics from "./CreatorDemographics";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const SocialMediaAnalytics = props => {
	const useStyles = makeStyles(theme => ({
		avatarAge:{
			backgroundColor: 'black',
			color:'white',
			width: theme.spacing(10),
			height: theme.spacing(10),
		},
		avatarGender:{
			backgroundColor: theme.palette.pink.main,
			color:'white',
			width: theme.spacing(10),
			height: theme.spacing(10),
		},
		box:{
			padding: theme.spacing(1),
			width:'75%',
			color:'white',
			textAlign:'center'
		},
		container: {
			// marginTop: theme.spacing(5),
			width:'100%'
		},
		divider: {
			height: 40,
			width: 2,
			backgroundColor: theme.palette.pink.main,
			marginRight: "auto",
		},
		dividerTop: {
			height: 10,
			marginLeft: 10,
			marginRight: 10,
			backgroundColor: grey[700],
			display: "inline-block"
		},
		title: {

		},
		topStats: {
			lineHeight: "normal",
			textAlign: "center",
			marginRight: "auto",

		},
		topStatsLabel: {
			lineHeight: "normal",
			textAlign: "center",
			fontWeight:400
		},
		speedDialActionStyle: {
			backgroundColor: "#cd9988",
			color: "white",
			"&:hover": {
				backgroundColor: "#e8ad9c"
			},
		},
		analytics:{
			alignItems:'center',
			justifyContent:'center',
			marginBottom: theme.spacing(3),
		}
	}));

	const {
		creator,
		isMobile,
	} = props;
	const classes = useStyles();

	const currentAnalytics = [
		{ currentStatsName:'instagramStats', socialMediaName:'Instagram', shouldShow: true, primary: creator?.instagramStats?.primary ?? false},
		{ currentStatsName:'youtubeStats', socialMediaName:'Youtube', shouldShow: creator?.youtubeStats && creator?.youtubeStats.link, primary: creator?.youtubeStats?.primary ?? false},
		{ currentStatsName:'tiktokStats', socialMediaName:'TikTok', shouldShow: creator?.tiktokStats && creator?.tiktokStats.link, primary: creator?.tiktokStats?.primary ?? false},
	]

	const Analytics = props => {
		const {
			shouldShow,
			socialMediaName,
			currentStatsName,
		} = props;


	return(
		<Grid item xs={12} style={{display: shouldShow? "inherit" :'none',paddingTop:'6rem'}} id={`${socialMediaName} Analytics`}>
			<Grid container style={{ alignItems:'center', justifyContent:'center', }} >
				<Grid container className={classes.analytics}>
					<Grid item xs={12} sm={3} md={3}>
						<Box bgcolor="pink.main" className={classes.box}>
							<Typography
								variant="h5"
								className={classes.title}
							>
								{`${socialMediaName} Analytics`}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs> 
						<CreatorStatistics {...props} classes={classes} currentStats={creator?.[currentStatsName]} currentStatsName={currentStatsName} />
					</Grid>
				</Grid>
				<CreatorDemographics {...props} classes={classes} currentStats={creator?.[currentStatsName]} />
			</Grid>
		</Grid>
	)
	}
	// true values first
        // return (x === y)? 0 : x? -1 : 1;
	
	const sortedCurrentAnalytics = currentAnalytics.sort((x, y) => (y.primary - x.primary));

	return (
		<Grid container className={classes.container} id='SocialMediaAnalytics'>
			{
				sortedCurrentAnalytics
					.map(
						(currentStat, key) =>
							<Analytics
								classes={classes}
								creator={creator}
								currentStatsName={currentStat.currentStatsName}
								key={key}
								isMobile={isMobile}
								socialMediaName={currentStat.socialMediaName}
								shouldShow={currentStat.shouldShow}
							/>
					)
			}
		</Grid>
	)
}
export default SocialMediaAnalytics;
