import React from "react";

import {
	Button,
	Grid,
	TextField,
	InputAdornment,
	Paper,
	Typography
} from "@material-ui/core";

const TopAgeListItem = props => {
	const {
		topAge,
		currentStats,
		currentStatsName,
		handleChangeArrayObject,
		index
	} = props;

	return (
		<Grid container alignItems="center" justifyContent="center" spacing={1}>
			<Grid item xs={6}>
				<TextField
					fullWidth
					autoCapitalize="false"
					name="topAge"
					value={topAge.age}
					onChange={handleChangeArrayObject(topAge.id ?? index , "age",currentStats, currentStatsName)}
					label={"Top Age #" + (topAge.id ? topAge.id + 1 : index + 1)}
					margin="dense"
					variant="filled"
					color="primary"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					fullWidth
					autoCapitalize="false"
					label="Percentage"
					name="topAge"
					margin="dense"
					variant="filled"
					color="primary"
					value={topAge.percentage}
					onChange={handleChangeArrayObject(topAge.id ?? index, "percentage",currentStats, currentStatsName)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">%</InputAdornment>
						)
					}}
				/>
			</Grid>
		</Grid>
	)
}

const TopAgeInput = props => {
	const {
		classes,
		currentStats,
		currentStatsName,
		addField
	} = props;

   return (
   		<Grid item xs={12}>
			<Paper className={classes.paper} elevation={0}>
				<Typography className={classes.paperTitle}>Top Age</Typography>
				<Grid container alignItems="center" justifyContent="center" spacing={1}>
					{currentStats.topAge.map((topAge, index) => (
						<TopAgeListItem {...props} topAge={topAge} key={index} index={index}/>
					))}
					<Grid item xs={12}>
						<Button
							variant="contained"
							onClick={_ =>
								addField({ id: (currentStats.topAge.length), age: "", percentage: "" }, "topAge", currentStatsName)
							}
							fullWidth
						>
							More Top Age
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
   	);
};

export default TopAgeInput;
