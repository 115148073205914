import database, { firebaseRef, fbFunctions } from "../data/Firebase";
import store from "../Store";

import { cloneDeep } from "lodash";
import moment from "moment";
import numeral from "numeral";

export function fetchCreatorsWithCallback(callBack) {
	return (dispatch) => {
		if (!store.getState().app.creatorsLoading) {
			dispatch(creatorsLoading(true));
			let request = database.collection("creators");
			if (store.getState().user.role === "manager") {
				request = request
					.where("managers", "array-contains", {
						fullName:
							store.getState().user.firstname +
							" " +
							store.getState().user.lastname,
						uid: store.getState().user.uid,
					})
					.where("status", "in", ["active", "archived"]);
			} else if (store.getState().user.role === "head-manager") {
				request = request.where("status", "in", ["active", "archived"]);
			} else if (store.getState().user.role === "creator") {
				request = request.doc(store.getState().user.creator);
			}
			request
				.get()
				.then(async function (querySnapshot) {
					let creators = [];
					if (store.getState().user.role === "creator") {
						creators.push({ id: querySnapshot.id, ...querySnapshot.data() });
					} else {
						querySnapshot.forEach(function (doc) {
							creators.push({ id: doc.id, ...doc.data() });
						});
					}
					let fetchMore = [];
					creators.forEach((creator, key) => {
						//Modify the entry an add name which will concatenate first and last name
						creator.name = `${creator.firstname} ${creator.lastname}`;
						creator.followers = numeral(creator.followers).value();
						//Events
						fetchMore.push(
							database
								.collection("creators/" + creator.id + "/events")
								.get()
								.then(function (eventsRes) {
									let events = [];
									if (eventsRes.docs.length !== 0) {
										eventsRes.forEach(function (doc) {
											let event = doc.data();
											event = {
												...event,
												date: moment(event.date.seconds * 1000),
												endDate: moment(event.endDate.seconds * 1000),
											};
											events.push({ id: doc.id, ...event });
										});
									}
									creators[key].events = events;
								})
						);

						//Project_list
						fetchMore.push(
							database
								.collection("creators/" + creator.id + "/projects_list")
								.get()
								.then(function (projectsListRes) {
									let projects_list = {};
									if (projectsListRes.docs.length !== 0) {
										projectsListRes.forEach(function (doc) {
											let project_list = doc.data();
											projects_list[doc.id]=project_list
										});
									}
									creators[key].projects_list = projects_list;
								})
						);

						// Projects
						fetchMore.push(
							database
								.collection("creators/" + creator.id + "/projects")
								.orderBy("creationDate", "desc")
								.get()
								.then(function (projectsRes) {
									let projects = [];
									if (projectsRes.docs.length !== 0) {
										projectsRes.forEach(function (doc) {
											let project = doc.data();
											if(project.dueDate){
												project = {
													...project,
													dueDate: moment(project.dueDate.seconds * 1000),
												};
											}

											if (typeof project.paymentDate !== "undefined") {
												project = {
													...project,
													paymentDate: moment(
														project.paymentDate.seconds * 1000
													),
												};
											}
											const deliverables = [...project.deliverables];
											deliverables.forEach((deli, key) => {
												deli.approval = {
													...deli.approval,
													date: deli.approval.date ? moment(deli.approval.date.seconds * 1000): null,
													startDate: deli.approval.startDate ? moment(deli.approval.startDate.seconds * 1000): null,
													endDate: deli.approval.endDate? moment(deli.approval.endDate.seconds * 1000): null,
												};
												deli.post = {
													...deli.post,
													date: deli.post.date ? moment(deli.post.date.seconds * 1000): null,
													startDate: deli.post.startDate ? moment(deli.post.startDate.seconds * 1000): null,
													endDate: deli.post.endDate ? moment(deli.post.endDate.seconds * 1000): null,
												};
											});
											projects.push({ id: doc.id, ...project });
										});
									}
									creators[key].projects = projects;
								})
						);
					});
					await Promise.all(fetchMore);
					callBack();
					dispatch(fetchCreatorsFulfilled(creators));
					dispatch(creatorsLoading(false));
				})
				.catch((error) => console.log(error));
		}
	};
}

export function fetchProjectsList(creator){
	return (dispatch) => {
		const projects_list = creator.projects_list;
		if (Object.keys(projects_list).length !== 0) {
			const activeData = projects_list.active ;
			const closedData = projects_list.closed;
			dispatch(reorderProjectList('active', activeData));
			dispatch(reorderProjectList('closed', closedData));
			dispatch(createOrderedProjects(creator.projects, projects_list));
		} else {
			dispatch(reorderProjectList('active', []));
			dispatch(reorderProjectList('closed', []));
			dispatch(reorderedOrderedProjects('active', []));
			dispatch(reorderedOrderedProjects('closed',[]));
		}
	}
}

export function createOrderedProjects(projects, projectsList){
	return (dispatch) => {
		const activeProjects = [];
		const closedProjects = [];

		projects.forEach(project => {
			project.status === "active"
					? activeProjects.push(project)
					: closedProjects.push(project)
		});
		const orderedActiveProjects = orderProjects(activeProjects, projectsList.active);
		const orderedClosedProjects = orderProjects(closedProjects, projectsList.closed);
		dispatch(reorderedOrderedProjects('active', orderedActiveProjects));
		dispatch(reorderedOrderedProjects('closed',orderedClosedProjects));
	}
}

export function fetchCreators() {
	return (dispatch) => dispatch(fetchCreatorsWithCallback((_) => null));
}

export function fetchCreatorWithCallback(doc, callback) {
	return (dispatch) => {
		if (!store.getState().app.creatorsLoading) {
			dispatch(creatorsLoading(true));
			database
				.collection("creators")
				.doc(doc)
				.get()
				.then(async function (querySnapshot) {
					let creator = { id: doc, ...querySnapshot.data() };
					callback(creator);
					dispatch(fetchCreatorFulfilled([creator]));
					dispatch(creatorsLoading(false));
				})
				.catch((error) => console.log(error));
		}
	};
}

export function fetchCreator(doc, callback) {
	return (dispatch) => dispatch(fetchCreatorWithCallback(doc, (_) => null));
}

export function updateCreatorWithCallback(creator, callBack) {
	return (dispatch) => {
		creator = { ...creator };
		const ref = creator.id;
		delete creator.id;
		delete creator.projects;
		delete creator.events;
		database
			.collection("creators")
			.doc(ref)
			.set({
				...creator,
				lastUpdate: firebaseRef.firestore.Timestamp.fromDate(new Date()),
			})
			.then((res) => callBack())
			.catch((error) => console.log(error));
	};
}

export function updateCreator(creator) {
	return (dispatch) =>
		dispatch(updateCreatorWithCallback(creator, (_) => null));
}

export function newCreator(creator, callBack) {
	return (dispatch) => {
		dispatch(addingCreatorStatus(true));
		const date = new Date();
		creator.email = creator.email.trim().toLowerCase();
		date.setDate(date.getDate() - 2);
		database
			.collection("creators")
			.add({
				...creator,
				lastUpdate: firebaseRef.firestore.Timestamp.fromDate(date),
			})
			.then((doc) => {
				const user = {
					firstname: creator.firstname,
					lastname: creator.lastname,
					email: creator.email,
					role: "creator",
					creator: doc.id,
				};
				const addUser = fbFunctions.httpsCallable("addUser");
				addUser(user).then((result) => {
					dispatch(addingCreatorStatus(false));
					dispatch(fetchCreators());
					callBack();
				});
			})
			.catch((error) => console.log(error));
	};
}

export function addProject(project, creator, callBack) {
	return (dispatch) => {
		project = prepareProject(project);
		project = {
			...project,
			creationDate: firebaseRef.firestore.Timestamp.now(),
		};
		database
			.collection("creators/" + creator.id + "/projects")
			.add(project)
			.then((_) => {
				dispatch(addProjectToProjectsList(project.status, creator.id, _.id ));
				dispatch(fetchCreatorsWithCallback(callBack));
			})
			.catch((error) => console.log(error));
	};
}

export function updateProject(project, creator, callBack) {
	return (dispatch) => {
		let projectStatus = project.status;
		project = prepareProject(project);
		const ref = project.id;
		delete project.id;
		database
			.collection("creators/" + creator.id + "/projects")
			.doc(ref)
			.set(project)
			.then((_) => {
				if (projectStatus){
					let oldProjectStatus = projectStatus === "active" ? "closed" : "active";
					dispatch(deleteProjectFromProjectsList(oldProjectStatus, creator.id, ref));
					dispatch(addProjectToProjectsList(projectStatus, creator.id, ref));
				}
				dispatch(fetchCreatorsWithCallback(callBack));

			})
			.catch((error) => console.log(error));
	};
}

export function reorderProjects(projectStatus, creatorId, from, to, projects, callBack){
	return (dispatch) => {
		database
      	.collection("creators/" + creatorId + "/projects_list")
      	.doc(projectStatus)
      	.get()
      	.then(async function (querySnapshot) {
			if (!querySnapshot.exists) {
			    console.error('Project list not found');
			    return;
			}

		  	let data = querySnapshot.data();

			if (!data.projects_ids || data.projects_ids.length <= 0) {
			  console.error('No projects to move');
			  return;
			}

			data.projects_ids.splice(to, 0, ...data.projects_ids.splice(from, 1));

			database
		    .collection("creators/" + creatorId + "/projects_list")
		    .doc(projectStatus)
		    .set(data, {merge: true})
		    .then((_) => {
				dispatch(reorderProjectList(projectStatus, data));
				dispatch(reorderedOrderedProjects(projectStatus, projects));
			})
			.catch((error) => console.log(error));
		})
		.catch((error) => console.log(error));



	};
}
export function addProjectToProjectsList(projectStatus, creatorId, projectId){
	return (dispatch) => {
		database
      	.collection("creators/" + creatorId + "/projects_list")
      	.doc(projectStatus)
      	.get()
      	.then(async function (querySnapshot) {
			if (!querySnapshot.exists) {
				let data = {"projects_ids": [projectId]}
				database
					.collection("creators/" + creatorId + "/projects_list")
					.doc(projectStatus)
					.set(data)
					.then((_) => {
						dispatch(reorderProjectList(projectStatus, data));
					})
					.catch((error) => console.log(error));

			    return;
			}

		  	let data = querySnapshot.data();
		  	const index = data.projects_ids.indexOf(projectId);
			if (index > -1) {
				return;
			}


			data.projects_ids.unshift(projectId);

			database
		    .collection("creators/" + creatorId + "/projects_list")
		    .doc(projectStatus)
		    .set(data, {merge: true})
		    .then((_) => {
				dispatch(reorderProjectList(projectStatus, data));
			})
			.catch((error) => console.log(error));
		})
		.catch((error) => console.log(error));
	};
}

export function deleteProjectFromProjectsList(projectStatus, creatorId, projectId){
	return (dispatch) => {
		database
      	.collection("creators/" + creatorId + "/projects_list")
      	.doc(projectStatus)
      	.get()
      	.then(async function (querySnapshot) {
			if (!querySnapshot.exists) {
			    console.error('Project list not found');
			    return;
			}

		  	let data = querySnapshot.data();

			const index = data.projects_ids.indexOf(projectId);
			if (index > -1) {
				data.projects_ids.splice(index, 1);
			}

			database
		    .collection("creators/" + creatorId + "/projects_list")
		    .doc(projectStatus)
		    .set(data, {merge: true})
		    .then((_) => {
				dispatch(reorderProjectList(projectStatus, data));
			})
			.catch((error) => console.log(error));
		})
		.catch((error) => console.log(error));



	};
}
export function deleteProject(project, creator, callBack) {
	return (dispatch) => {
		project = prepareProject(project);
		const ref = project.id;
		delete project.id;
		database
			.collection("creators/" + creator.id + "/projects")
			.doc(ref)
			.delete()
			.then((_) => {
				dispatch(fetchCreatorsWithCallback(callBack));
				dispatch(deleteProjectFromProjectsList(project.status, creator.id, ref ));
			})
			.catch((error) => console.log(error));
	};
}

function orderProjects(filteredProjects, projectsList){
	let orderedProjects = [];

	if (projectsList && projectsList.projects_ids) {
		projectsList.projects_ids.forEach(function (projectId) {
			let project = filteredProjects.find(filteredProjects => filteredProjects.id === projectId)
			orderedProjects.push(project)
		});
	}

	return orderedProjects;

}

function prepareProject(project) {
	project = cloneDeep(project);

	if(project.dueDate){
		project = {
			...project,
			dueDate: firebaseRef.firestore.Timestamp.fromDate(project.dueDate.toDate()),
		};
	}
	if (typeof project.paymentDate !== "undefined") {
		if (moment.isMoment(project.paymentDate)) {
			project = {
				...project,
				paymentDate: firebaseRef.firestore.Timestamp.fromDate(
					project.paymentDate.toDate()
				),
			};
		} else {
			project = {
				...project,
				paymentDate: firebaseRef.firestore.Timestamp.fromDate(
					project.paymentDate
				),
			};
		}
	}
	const deliverables = [...project.deliverables];
	deliverables.forEach((deli, key) => {
		deli.approval = {
			...deli.approval,
			date: deli.approval.date ? firebaseRef.firestore.Timestamp.fromDate(
				deli.approval.date.toDate()
			): null,
			startDate: deli.approval.startDate ? firebaseRef.firestore.Timestamp.fromDate(
				deli.approval.startDate.toDate()
			): null,
			endDate: deli.approval.endDate? firebaseRef.firestore.Timestamp.fromDate(
				deli.approval.endDate.toDate()
			): null,
		};
		deli.post = {
			...deli.post,
			date: deli.post.date ? firebaseRef.firestore.Timestamp.fromDate(deli.post.date.toDate()): null,
			startDate: deli.post.startDate ? firebaseRef.firestore.Timestamp.fromDate(deli.post.startDate.toDate()): null,
			endDate: deli.post.endDate ? firebaseRef.firestore.Timestamp.fromDate(deli.post.endDate.toDate()): null,
		};
	});
	project = {
		...project,
		deliverables: deliverables,
	};
	return project;
}

export function addEvent(event, creator, callBack) {
	return (dispatch) => {
		event = {
			...event,
			date: firebaseRef.firestore.Timestamp.fromDate(event.date.toDate()),
			endDate: firebaseRef.firestore.Timestamp.fromDate(event.endDate.toDate()),
		};
		database
			.collection("creators/" + creator.id + "/events")
			.add(event)
			.then((_) => {
				dispatch(fetchCreatorsWithCallback(callBack));
			})
			.catch((error) => console.log(error));
	};
}

export function updateEvent(event, creator, callBack) {
	return (dispatch) => {
		const ref = event.id;
		event = {
			...event,
			date: firebaseRef.firestore.Timestamp.fromDate(event.date.toDate()),
			endDate: firebaseRef.firestore.Timestamp.fromDate(event.endDate.toDate()),
		};
		delete event.id;
		database
			.collection("creators/" + creator.id + "/events")
			.doc(ref)
			.set(event)
			.then((_) => {
				dispatch(fetchCreatorsWithCallback(callBack));
			})
			.catch((error) => console.log(error));
	};
}

export function shareCreator(creator, email, type, callBack) {
	database
		.collection("emails")
		.add({
			from: "I+AM House <iamhouse@iam-influenceall.com>",
			to: email,
			message: {
				subject: "I+AM House - " + creator.firstname + " " + creator.lastname,
				html:
					"Hey!<br /> <br /> You have been invited to view the profile of " +
					creator.firstname +
					" " +
					creator.lastname +
					": <a href='https://house.iam-influenceall.com/" +
					type +
					"/" +
					creator.id +
					"' target='_blank' >https://house.iam-influenceall.com/" +
					type +
					"/" +
					creator.id +
					" </a> <br /> <br /> Thank you! <br /> I+AM House<br />",
			},
		})
		.then(async (_) => {
			callBack();
			return {
				type: "CREATOR_SHARE",
			};
		});
	return {
		type: "CREATOR_SHARE",
	};
}

export function addingCreatorStatus(status) {
	return {
		type: "ADD_CREATOR_STATUS",
		payload: status,
	};
}

export function reorderProjectList(projectStatus, projectList) {
	return {
		type: "REORDERED_PROJECTS_LIST",
		payload: {projectStatus, projectList}
	};
}

export function reorderedOrderedProjects(projectStatus, orderedProjects) {
	return {
		type: "REORDERED_ORDERED_PROJECTS",
		payload: {projectStatus, orderedProjects}
	};
}

export function fetchCreatorsFulfilled(creators) {
	return {
		type: "FETCH_CREATORS_FULFILLED",
		payload: creators,
	};
}

export function fetchCreatorFulfilled(creator) {
	return {
		type: "FETCH_CREATOR_FULFILLED",
		payload: creator,
	};
}

export function creatorsLoading(status) {
	return {
		type: "CREATORS_LOADING",
		payload: status,
	};
}
