import React from "react";
import SocialMediaInput from "./SocialMediaInput";
import {TextField, Grid, Typography} from "@material-ui/core";

const NewCreatorStep2 = props => {
return(
		// <Grid container alignItems="center">
		<>
			<Grid
				container
				alignItems="center"
				justifyContent="flex-start"
			
				style={{margin:'0', paddingTop:'1rem'}}
			>
				<Grid item xs={12} 				 >
					<Typography variant="h6" className={props.classes.formContainer}>
						BIO
					</Typography>
				</Grid>
				<Grid item xs={12} className={props.classes.formContainer} >
					<TextField
						fullWidth
						multiline
						row={10}
						autoCapitalize="false"
						label="Bio"
						name="bio"
						margin="dense"
						variant="filled"
						color="primary"
						value={props.creator.bio}
						onChange={props.handleChange}
						/>
				</Grid>
			</Grid>
			<SocialMediaInput
				currentStatsName="instagramStats"
				hasLinkInput={false}
				hasPictureInput={true}
				socialMediaName="INSTAGRAM"
				{...props}
			/>
			<SocialMediaInput
				currentStatsName="youtubeStats"
				hasLinkInput={true}
				hasPictureInput={false}
				socialMediaName="YOUTUBE"
				style={{marginTop: "1rem"}}
				{...props}
			/>
			<SocialMediaInput
				currentStatsName="tiktokStats"
				hasLinkInput={true}
				hasPictureInput={false}
				socialMediaName="TIKTOK"
				style={{marginTop: "1rem"}}
				{...props}
			/>
		</>
	)
};

export default NewCreatorStep2;
